import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import SimpleCard from './SimpleCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  Spacer: {
    marginTop: '3rem',
  },
}));

function App() {
  const classes = useStyles();
  const sites = [
    {
      name: '/',
      description: 'this app',
      subdomain: 'home',
    },
    // {
    //   name: 'Bankie',
    //   description: 'Perreault Family Banking App',
    //   subdomain: 'bank',
    // },
    {
      name: 'Release Manager',
      description: 'React UI for ClassLink Release Manager',
      subdomain: 'releasemanager',
    },
    {
      name: 'Roster Server',
      description: 'ClassLink Roster Server',
      subdomain: 'oneroster',
    },
    {
      name: 'Webhooks',
      description: 'Webhooks used by github for CI',
      subdomain: 'webhooks',
    },
    {
      name: 'OneSync Videos',
      description: 'ClassLink OneSync training videos (unofficial)',
      subdomain: 'onesync',
    },
    {
      name: 'ORCache Analyzer',
      description: 'ClassLink RS-to-ORCache comparison',
      subdomain: 'orcache',
    },
    {
      name: 'Headers Viewer',
      description: 'View headers of a request. Useful for nginx troubleshooting',
      subdomain: 'headers',
    },
    {
      name: 'What is My SSH IP',
      description: 'SSH to this url and see your IP on the page',
      subdomain: 'sship',
    }
  ];
  return (
    <React.Fragment>
      <div className={classes.Spacer} />
      <Container maxWidth="xl">
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {sites.map((site, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <SimpleCard
                    name={site.name}
                    description={site.description}
                    subdomain={site.subdomain}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default App;
