import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  highlight: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
  lastUpdated: {
    display: 'inline-block',
    transition: '1s',
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    margin: '.25rem 0',
  },
}));

const SimpleCard = (props) => {
  const classes = useStyles();
  const [latest, setLatest] = useState('');
  const [updatedClasses, setUpdatedClasses] = useState([classes.lastUpdated]);
  const [fileNotFound, setFileNotFound] = useState(false);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
      callback();
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      const tick = () => {
        savedCallback.current();
      };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  const updateLatest = (newVal) => {
    const oldVal = latest;
    if (oldVal !== newVal) {
      setLatest(newVal);
      const defaultClasses = [classes.lastUpdated];
      if (oldVal !== '') {
        setUpdatedClasses([...defaultClasses, classes.highlight]);
        if (props.subdomain !== 'home') {
          setTimeout(() => setUpdatedClasses(defaultClasses), 9000);
        } else {
          setLatest(newVal);
        }
      }
    }
  };

  const getLatest = async () => {
    try {
      if (!fileNotFound) {
        const ver = Date.now();
        const res = await axios.request({
          url: `/latest/${props.subdomain}.txt?v=${ver}`,
          method: 'GET',
          responseType: 'text',
        });
        if (res.data === '') {
          if (latest !== 'Last Updated: empty')
            updateLatest('Last Updated: empty');
        } else {
          if (latest !== res.data) updateLatest(res.data);
        }
      }
    } catch (e) {
      if (e.response && e.response.status && e.response.status === 404) {
        setFileNotFound(true);
        updateLatest('Last Updated: file not found. reload to retry');
      } else if (e === '') {
        if (latest !== 'Last Updated: unknown')
          updateLatest('Last Updated: unknown');
      } else if (e.response && e.response.statusText) {
        if (latest !== 'Last Updated: error: ' + e.response.statusText)
          updateLatest('Last Updated: error: ' + e.response.statusText);
      } else {
        if (latest !== 'Last updated: unknown')
          updateLatest('Last Updated: unknown');
      }
    }
  };

  useInterval(getLatest, 10000);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {props.name}
        </Typography>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.subdomain}.oboesax.com
        </Typography>
        {props.description ? (
          <Typography variant="body2" component="p">
            {props.description}
          </Typography>
        ) : null}
        {latest === '' ? (
          <LinearProgress />
        ) : (
          <Typography
            variant="body2"
            className={updatedClasses.join(' ')}
            component="p"
          >
            {latest}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          href={`https://${props.subdomain}.oboesax.com`}
          target="_blank"
          color="primary"
          size="small"
        >
          Launch
        </Button>
      </CardActions>
    </Card>
  );
};

export default SimpleCard;
